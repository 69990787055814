import { lazy } from 'react'
import { NotFound } from '../features/NotFound'
import { Navigate } from 'react-router-dom'

const Login = lazy(() => import('../features/authentication/login'))
const PasswordRecovery = lazy(() => import('../features/authentication/password-recovery'))

const PasswordReset = lazy(() => import('../features/authentication/password-reset'))
const Register = lazy(() => import('../features/authentication/register'))
const VerifyCode = lazy(() => import('../features/authentication/verify-code'))
const ThankYouScreen = lazy(() => import('../features/authentication/thank-you-screen'))
const GuestGuard = lazy(() => import('../components/guards/guest-guard'))

const Imprint = lazy(() => import('../features/imprint'))
const DataProtection = lazy(() => import('../features/data-protection'))

const SurveyScreen = lazy(() => import('../features/survey'))

export const defaultRoutes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: 'verify-code',
        element: <VerifyCode />
      }
    ]
  },
  {
    path: 'thankYouForRegistration',
    element: <ThankYouScreen />
  },
  {
    path: 'imprint',
    element: <Imprint />
  },
  {
    path: 'data-protection',
    element: <DataProtection />
  },
  {
    path: 'survey',
    children: [
      { path: ':productionId', element: <SurveyScreen /> },
      {
        path: '',
        element: <NotFound />
      }
    ]
  },
  { path: '*', element: <Navigate to='/authentication/login' /> }
]
