export const getUsername = () => window.localStorage.getItem('username') || ''

export const setUsername = (username: string) => window.localStorage.setItem('username', username)

export const deleteUsername = () => {
  window.localStorage.removeItem('username')
}

export const getToken = (type: 'AccessToken' | 'RefreshToken'): string | null => {
  const tokens = window.localStorage.getItem('tokens')

  if (!tokens) return null

  return JSON.parse(tokens)[type]
}

export const setTokens = (AccessToken: string, RefreshToken: string) => {
  const parsedTokens = JSON.stringify({ AccessToken, RefreshToken })
  window.localStorage.setItem('tokens', parsedTokens)
}

export const deleteTokens = () => {
  window.localStorage.removeItem('tokens')
}

export const deleteLocalStorage = () => {
  deleteTokens()
  deleteUsername()
}
