import axios from 'axios'
import {
  deleteLocalStorage,
  getToken,
  getUsername,
  setTokens
} from '../services/local-storage-service'

const BASE_URL = `${process.env.REACT_APP_API_URL}/api/v1`

export const axiosInstance = axios.create({
  baseURL: BASE_URL
})

axiosInstance.defaults.headers['Content-Type'] = 'application/json'

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken('AccessToken')
    if (config.headers) {
      config.headers.Authorization = 'Bearer ' + token
    }

    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config

    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      try {
        const refreshToken = getToken('RefreshToken') || ''
        const username = getUsername()

        const response = await axiosInstance.post<RefreshAccessTokenResponse>('auth/refreshToken', {
          username,
          refreshToken
        })
        setTokens(response.data.AccessToken, refreshToken)

        // Retry the original request with the new token
        originalRequest.headers = { ...originalRequest.headers }
        originalRequest.headers.Authorization = `Bearer ${response.data.AccessToken}`

        axiosInstance.defaults.headers.common.Authorization = 'Bearer ' + response.data.AccessToken

        return await axiosInstance(originalRequest)
      } catch (err) {
        // Handle refresh token error or redirect to login

        deleteLocalStorage()
        history.pushState(null, '', '/authentication/login')
      }
    }

    return Promise.reject(error)
  }
)
