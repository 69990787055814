import { Box, Container, Typography, Button, Card, CardContent } from '@mui/material'
import React, { FC } from 'react'

export const NotFound: FC = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh'
    }}
  >
    <Container maxWidth='lg' sx={{ py: '120px' }}>
      <Card>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 4
          }}
        >
          <Typography align='center' color='textPrimary' variant='h2'>
            404: Die gesuchte Seite existiert nicht
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6
            }}
          >
            <Box
              alt='Under development'
              component='img'
              src='/static/error.svg'
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: 400
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6
            }}
          >
            <Button color='primary' component='a' href='/' variant='outlined'>
              Zurück zur Hauptseite
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  </Box>
)
