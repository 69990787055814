import { lazy } from 'react'
import DashboardLayout from '../components/layouts/dashboard-layout'
import { defaultRoutes } from './default-routes'

const AuthGuard = lazy(() => import('../components/guards/auth-guard'))
const ProductionListScreen = lazy(() => import('../features/production/list'))
const ProductionDetailScreen = lazy(() => import('../features/production/detail'))
const ProductionCreateScreen = lazy(() => import('../features/production/create'))

const Overview = lazy(() => import('../features/overview/employee'))
const Profile = lazy(() => import('../features/profile'))
const Settings = lazy(() => import('../features/settings'))
const Company = lazy(() => import('../features/company'))

export const employeeRoutes = [
  ...defaultRoutes,
  {
    path: '*',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      // {
      //   path: '',
      //   element: <ProductionListScreen />
      // },
      {
        path: '',
        element: <Overview />
      },
      {
        path: 'profile',
        element: <Profile />
      },
      {
        path: 'settings',
        element: <Settings />
      },
      {
        path: 'company',
        element: <Company />
      },
      {
        path: 'productions',
        children: [
          { path: '', element: <ProductionListScreen /> },
          { path: 'new', element: <ProductionCreateScreen /> },
          { path: ':productionId', element: <ProductionDetailScreen /> }
        ]
      }
    ]
  }
]
