import {
  experimentalStyled,
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
  Typography,
  Box,
  Button,
  AppBarProps
} from '@mui/material'
import { FC, useState } from 'react'
import { NavigateFunction, Link as RouterLink } from 'react-router-dom'
import Logo from '../../Logo'
import MenuIcon from '@mui/icons-material/Menu'
import useAuth from '../../../hooks/useAuth'
import { NAVBAR_HEIGHT } from '../constants'
import toast from 'react-hot-toast'

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void
  title: string
  navigate: NavigateFunction
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: theme.zIndex.drawer + 100
}))

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarMobileOpen, navigate, ...other } = props

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  // TODO: Clean up and remove setLLoading from whole project
  const { logout } = useAuth()

  const onSignOut = () => {
    logout().catch((err) => {
      toast.error(err.message)
    })
  }

  const handleProfile = () => {
    handleClose()
    navigate('profile')
  }

  const handleSettings = () => {
    handleClose()
    navigate('settings')
  }

  const { user } = useAuth()

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar
        sx={{
          minHeight: NAVBAR_HEIGHT,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row'
        }}
      >
        <Hidden lgUp>
          <IconButton color='primary' onClick={onSidebarMobileOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to='/'>
          <Logo
            style={{
              height: 40
            }}
          />
        </RouterLink>

        <Button onClick={handleClick}>
          <Hidden lgDown>
            <Box sx={{ m: 0 }}>
              <Typography color='primary' style={{ height: 20 }} textAlign='right'>
                {user?.firstname || ''} {user?.lastname || ''}
              </Typography>
              <Typography color='gray' textAlign='right'>
                {user?.role || '-'}
              </Typography>
            </Box>
          </Hidden>
          <Box sx={{ ml: 1, paddingTop: 1 }}>
            <img
              src='/static/placeholder-profile-image.png'
              height={40}
              style={{ borderRadius: 20 }}
            />
          </Box>
        </Button>
      </Toolbar>

      {/* 
      // TODO: Clean up
      <ProfileMenu
        anchorEl={anchorEl}
        isOpen={open}
        handleClose={handleClose}
        handleSignOut={onSignOut}
        handleProfile={handleProfile}
        handleSettings={handleSettings}
      /> */}
    </DashboardNavbarRoot>
  )
}

export default DashboardNavbar
