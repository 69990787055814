import { AccountBox, Business, Home, MovieCreation, Person, Settings } from '@mui/icons-material'

export const employeeSections = [
  {
    title: 'Productions',
    items: [
      {
        title: 'Home',
        path: '/',
        icon: <Home fontSize='small' />
      }
      // {
      //   title: 'Add new',
      //   path: '/productions/new',
      //   icon: <MovieCreation fontSize='small' />
      // }
    ]
  },
  // {
  //   title: 'Employees',
  //   icon: Person,
  //   items: [
  //     {
  //       title: 'Account',
  //       path: '/settings',
  //       icon: <AccountBox fontSize='small' />
  //     },
  //     {
  //       title: 'Profile',
  //       path: '/profile',
  //       icon: <Settings fontSize='small' />
  //     }
  //   ]
  // },
  {
    title: 'Settings',
    icon: Home,
    items: [
      {
        title: 'Company',
        path: '/company',
        icon: <Business fontSize='small' />
      },
      {
        title: 'Account',
        path: '/settings',
        icon: <AccountBox fontSize='small' />
      },
      {
        title: 'Profile',
        path: '/profile',
        icon: <Settings fontSize='small' />
      }
    ]
  }
]
