import './i18n/config'
import { useIsFetching } from '@tanstack/react-query'
import React, { Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
import { LoadingSpinner } from './components/loading-spinner'
import { adminRoutes } from './routes/admin-routes'
import { Footer } from './components/footer'
import LoadingScreen from './features/loading-screen'
import { Box } from '@mui/material'
import '/node_modules/flag-icons/css/flag-icons.min.css'
import useAuth from './hooks/useAuth'
import { userRoutes } from './routes/user-routes'
import { defaultRoutes } from './routes/default-routes'
import { employeeRoutes } from './routes/employee-routes'
import { superadminRoutes } from './routes/superadmin-routes'

const getRoutesFor = (user: User | null) => {
  if (!user) return defaultRoutes

  switch (user.role) {
    case 'USER':
      return userRoutes
    case 'EMPLOYEE':
      return employeeRoutes
    case 'ADMIN':
      return adminRoutes
    case 'SUPERADMIN':
      return superadminRoutes
  }
}

function App() {
  const isFetching = useIsFetching()
  const { isLoading, user } = useAuth()
  const content = useRoutes(getRoutesFor(user))

  return (
    <Suspense fallback={<LoadingScreen />}>
      <LoadingSpinner isVisible={isLoading || isFetching > 0} />
      <Box sx={{ marginBottom: 5 }}>{content}</Box>
      <Footer />
    </Suspense>
  )
}

export default App
