import { Button } from '@mui/material'

export const Footer = () => (
  <div
    style={{
      background: 'white',
      position: 'fixed',
      left: 0,
      bottom: 0,
      padding: '5px',
      paddingLeft: '15px',
      width: '100%',
      zIndex: 999999
    }}
  >
    <Button href='/imprint'>Imprint</Button>
    <Button href='/data-protection'>Data protection information</Button>
  </div>
)
